<template>
  <div class="check-wrap h100 dp-flex flex-d-c">
    <el-checkbox
      :indeterminate="indeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
      >全选</el-checkbox
    >
    <el-checkbox-group
      class="dp-flex flex-d-c flex-1 overflow-auto"
      v-model="checkedDatas"
      @change="handleCheckedDataChange"
    >
      <el-checkbox
        class="mb-10"
        v-for="item in allCheckboxDatas"
        :label="item.keyName"
        :key="item.keyName"
        @change="$forceUpdate()"
      >
        {{ item.name }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkAll: false,
      indeterminate: false,
      checkedDatas: []
    };
  },
  props: {
    checkedInitDatas: {
      type: Array,
      default: () => []
    },
    allCheckboxDatas: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    allCheckboxIdArr() {
      return this.allCheckboxDatas.map(item => item.keyName);
    }
  },
  methods: {
    // 全选操作
    handleCheckAllChange(val) {
      this.checkedDatas = val ? this.allCheckboxIdArr : [];
      this.indeterminate = false;
      // 选中的数据传给父组件
      this.$emit("post-checked-datas", this.checkedDatas);
    },
    // 选择触发
    handleCheckedDataChange(value) {
      // console.log("handleCheckedDataChange(value)", value);
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.allCheckboxIdArr.length;
      this.indeterminate =
        checkedCount > 0 && checkedCount < this.allCheckboxIdArr.length;
      // 选中的数据传给父组件
      this.$emit("post-checked-datas", value);
    }
  },
  mounted() {
    this.checkedDatas = this.checkedInitDatas;
  },
  watch: {
    checkedInitDatas: {
      handler(val) {
        this.checkedDatas = val;
      },
      deep: true
    },
    checkedDatas: {
      handler(val) {
        // console.log("watch-checkedDatas", val);
        //解决重置时checkedDatas改变而checkAll和indeterminate未动态变化的bug
        this.checkAll =
          val.length &&
          this.allCheckboxIdArr.length &&
          val.length == this.allCheckboxIdArr.length;
        this.indeterminate =
          val.length > 0 && val.length < this.allCheckboxIdArr.length;
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.check-wrap {
  .el-checkbox__label {
    color: $theme-color;
    padding: 0 8px;
  }
  & > .el-checkbox {
    margin: 15px 0 10px;
  }
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
  }
  .el-checkbox-group {
    margin-left: 15px;
  }
}
</style>