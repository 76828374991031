<template>
  <el-checkbox-group
    @change="handleChange"
    v-model="checkList"
    class="dp-flex flex-d-c group-wrap"
  >
    <el-checkbox
      class="mb-10"
      v-for="item in allCheckboxDatas"
      :key="item.keyName"
      :label="item.keyName"
      >{{ item.columnGroup.title+" / "+item.name }}</el-checkbox
    >
  </el-checkbox-group>
</template>

<script>
export default {
  props: {
    allCheckedDatas: {
      type: Array,
      default: () => []
    },
    allCheckboxDatas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkList: []
    };
  },
  methods: {
    handleChange(val) {
      this.$emit("change-search-checks", val)
    }
  },
  watch: {
    allCheckedDatas: {
      handler(val) {
        this.checkList = val.map(item => item.keyName);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.group-wrap {
  .el-checkbox__label {
    color: $theme-color;
    padding: 0 8px;
  }
  .el-checkbox {
    margin: 0 0 10px;
  }
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
  }
}
</style>